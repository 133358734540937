import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Navigation from "./components/Navbar";
import Philosophy from "./pages/Philosophy";
import Resources from "./pages/Resources";
import Contact from "./pages/Contact";
import ServicePage from "./pages/ServicePage";
import ScrollToTop from "./components/ScrollToTop";
import Footer from "./components/Footer";
import HomeContact from "./pages/HomeContact";

function MainPage() {
  return (
    <div className="mainpage-visual">
      <div id="Home">
        <Home />
      </div>
      <div id="Philosophy">
        <Philosophy />
      </div>
      <div id="HomeContact">
        <HomeContact />
      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Navigation />
        <div className="content">
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/servicePage" element={<ServicePage />} />
            <Route path="/resources" element={<Resources />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
