// src/pages/Philosophy.js
import React from "react";
import "../styles/Philosophy.css";

function Philosophy() {
  return (
    <div className="philo-background">
      <div className="philo-container">
        {/*opening text */}
        <div className="philo-content">
          Our mission is to fill the gap, by helping advance the business
          through optimizing monetary operations of the well known business and
          install and additional system of control for our clients.
        </div>
        <div className="philo-row">
          <div className="philo-image">
            <img
              src="/core.png"
              alt="Industry insights"
              className="core-image"
            />
          </div>
          <div className="philo-text">
            After getting experience in the industry on positions from general
            labour to managerial roles, a need of streamline became visible.
          </div>
        </div>
        <div className="philo-row">
          <div className="philo-image">
            <img
              src="/core2.png"
              alt="Industry insights"
              className="core-image"
            />
          </div>
          <div className="philo-text">
            Commitment to transform and enhance the efficiency of the Canadian
            manufacturing sector, especially in the Greater Toronto Area, by
            addressing critical needs in treasury management and cost analysis.
          </div>
        </div>
        <div className="philo-row">
          <div className="philo-image">
            <img
              src="/core3.png"
              alt="Industry insights"
              className="core-image"
            />
          </div>
          <div className="philo-text">
            Our mission is to fill the gap, by helping advance the business
            through optimizing monetary operations of the well known business
            and install and additional system of control for our clients.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Philosophy;
