import React from "react";
import "../styles/Contact.css";

function ContactPage() {
  return (
    <div className="background">
      <div className="container">
        <div className="title">Get in Touch</div>
        {/*opening text */}
        <div className="content">
          “At Trana Solutions we bring the experience of our clients into the
          present, offering fresh, innovative perspectives to transform your
          financial operations.”
          <br /> <br />
          Let’s work together to create a more efficient, cost-effective future
          for your business. Contact us today to learn how we can help.
        </div>
        {/* contact links */}
        <div className="contact-info">
          <p>
            Phone: <a href="tel:437-234-3261">(437)-234-3261</a>
          </p>
          <p>
            Email:{" "}
            <a href="mailto:support@trana-solutions.com">
              support@trana-solutions.com
            </a>
          </p>
          <p>
            Address:{" "}
            <a href="https://www.google.com/maps/place/110+Ferrier+St,+Markham,+ON+L3R+2Z5/@43.8258867,-79.329953,19.91z/data=!4m6!3m5!1s0x89d4d47f670ddb03:0x8a66d8ac9e048d60!8m2!3d43.8247054!4d-79.3313996!16s%2Fg%2F11dft031vp?entry=ttu&g_ep=EgoyMDI0MDkyNC4wIKXMDSoASAFQAw%3D%3D">
              110 Ferrier St, Markham, ON L3R 2Z5, Canada
            </a>
          </p>
        </div>
        {/*map image clickable */}
        <div className="map-section">
          <a
            href="https://www.google.com/maps/place/110+Ferrier+St,+Markham,+ON+L3R+2Z5,+Canada/@43.8247054,-79.3339745,17z/data=!3m1!4b1!4m6!3m5!1s0x89d4d47f670ddb03:0x8a66d8ac9e048d60!8m2!3d43.8247054!4d-79.3313996!16s%2Fg%2F11dft031vp?entry=ttu&g_ep=EgoyMDI0MDgyOC4wIKXMDSoASAFQAw%3D%3D"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={`${process.env.PUBLIC_URL}/contactLocation.jpg`}
              alt="Trana Solutions Location"
              className="map-image"
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default ContactPage;
