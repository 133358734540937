import React from "react";
import "../styles/HomeContact.css";

function HomeContact() {
  return (
    <div className="homeContact-background">
      <div className="homeContact-container">
        <div className="homeContact-title">How to Reach Us</div>
        {/* wrapper to help have text on left image on the right*/}
        <div className="homeContact-content-wrapper">
          {/* contact links */}
          <div className="homeContact-contact-info">
            <p>
              Phone: <a href="tel:437-234-3261">(437)-234-3261</a>
            </p>
            <p>
              Email:{" "}
              <a href="mailto:support@trana-solutions.com">
                support@trana-solutions.com
              </a>
            </p>
            <p>
              Address:{" "}
              <a href="https://www.google.com/maps/place/110+Ferrier+St,+Markham,+ON+L3R+2Z5/@43.8258867,-79.329953,19.91z/data=!4m6!3m5!1s0x89d4d47f670ddb03:0x8a66d8ac9e048d60!8m2!3d43.8247054!4d-79.3313996!16s%2Fg%2F11dft031vp?entry=ttu&g_ep=EgoyMDI0MDkyNC4wIKXMDSoASAFQAw%3D%3D">
                110 Ferrier St, Markham, ON L3R 2Z5, Canada
              </a>
            </p>
          </div>
          {/*map image clickable */}
          <div className="image-section">
            <div className="map-section">
              <a
                href="https://www.google.com/maps/place/110+Ferrier+St,+Markham,+ON+L3R+2Z5,+Canada/@43.8247054,-79.3339745,17z/data=!3m1!4b1!4m6!3m5!1s0x89d4d47f670ddb03:0x8a66d8ac9e048d60!8m2!3d43.8247054!4d-79.3313996!16s%2Fg%2F11dft031vp?entry=ttu&g_ep=EgoyMDI0MDgyOC4wIKXMDSoASAFQAw%3D%3D"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/contactLocation.jpg`}
                  alt="Trana Solutions Location"
                  className="map-image"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeContact;
