/* Imports */
import React, { useState, useEffect } from "react";
import "../styles/Navbar.css";
import { NavLink } from "react-router-dom";
import { FaRegListAlt } from "react-icons/fa";

function Navbar() {
  /* state variable to track if navbar is open or not*/
  const [isOpen, setIsOpen] = useState(false);
  /* state variable to track the size of the screen */
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);

  /* function to toggle navbar open/close */
  const toggleNavbar = () => {
    setIsOpen((prevState) => !prevState); /*flip value */
  };
  /*function to close navbar*/
  const closeNavbar = () => {
    setIsOpen(false);
  };
  useEffect(() => {
    /*function to handle window resizing */
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900); /* check size of the screen */
    };

    /*check if been clicked outside navbar */
    const handleClickOutside = (event) => {
      const navbarElement = document.querySelector(".navbar-background");

      // If the navbar is open and the click is outside the navbar, close it
      if (isOpen && !navbarElement.contains(event.target)) {
        closeNavbar();
      }
    };

    //event listener to detect clicks outside the navbar
    document.addEventListener("mousedown", handleClickOutside);

    //// Add a resize event listener to handle window resizing
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listeners
    return () => {
      window.removeEventListener("resize", handleResize); // Remove resize listener
      document.removeEventListener("mousedown", handleClickOutside); // Remove mousedown listener
    };
  }, [isOpen]); // Dependency array: the effect depends on the 'isOpen' state

  // Debounced scroll event listener for transparency effect
  useEffect(() => {
    let lastScrollTop = 0;

    const handleScroll = () => {
      const navbar = document.querySelector(".navbar-background");
      if (window.scrollY > 50) {
        navbar.classList.add("transparent");
      } else {
        navbar.classList.remove("transparent");
      }
    };

    const debounceScroll = () => {
      clearTimeout(lastScrollTop);
      lastScrollTop = setTimeout(handleScroll, 50);
    };

    window.addEventListener("scroll", debounceScroll);

    return () => {
      window.removeEventListener("scroll", debounceScroll);
    };
  }, []);
  return (
    <nav>
      <div className="navbar-background">
        {/* if screen is small turn on isMobile */}
        {isMobile && (
          <div className="navbar-toggle" onClick={toggleNavbar}>
            <FaRegListAlt size={50} color="white" />
          </div>
        )}
        <ul className={`navbar ${isOpen ? "opened" : "closed"}`}>
          <li>
            <NavLink
              to="/"
              onClick={closeNavbar}
              style={({ isActive }) => {
                return {
                  color: "white", // Always white
                  textDecoration: isActive ? "underline" : "none", // Underline when active
                  textDecorationColor: isActive ? "orange" : "transparent", // Orange underline when active
                  textUnderlineOffset: "5px",
                  textDecorationThickness: isActive ? "3px" : "inherit",
                };
              }}
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/servicePage"
              onClick={closeNavbar}
              style={({ isActive }) => {
                return {
                  color: "white", // Always white
                  textDecoration: isActive ? "underline" : "none", // Underline when active
                  textDecorationColor: isActive ? "orange" : "transparent", // Orange underline when active
                  textUnderlineOffset: "5px",
                  textDecorationThickness: isActive ? "3px" : "inherit",
                };
              }}
            >
              Services
            </NavLink>
          </li>
          <li className="home-link">
            <NavLink to="/" onClick={closeNavbar}>
              <img
                src={`${process.env.PUBLIC_URL}/logo.png`}
                alt="Logo"
                className="logo"
              />
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/resources"
              onClick={closeNavbar}
              style={({ isActive }) => {
                return {
                  color: "white", // Always white
                  textDecoration: isActive ? "underline" : "none", // Underline when active
                  textDecorationColor: isActive ? "orange" : "transparent", // Orange underline when active
                  textUnderlineOffset: "5px",
                  textDecorationThickness: isActive ? "3px" : "inherit",
                };
              }}
            >
              Resources
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/contact"
              onClick={closeNavbar}
              style={({ isActive }) => {
                return {
                  color: "white", // Always white
                  textDecoration: isActive ? "underline" : "none", // Underline when active
                  textDecorationColor: isActive ? "orange" : "transparent", // Orange underline when active
                  textUnderlineOffset: "5px",
                  textDecorationThickness: isActive ? "3px" : "inherit",
                };
              }}
            >
              Contact
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
