// src/pages/Home.js
import React from "react";
import "../styles/Home.css";

// simple homepage opening
function Home() {
  return (
    <div className="home-background">
      <div className="home-container">
        <div class="hometitle1">Welcome to </div>
        <div class="hometitle">Trana Solutions</div>
        <div className="home-content">
          Simplifying your financial management &
        </div>
        <div className="home-content">Enhancing operational efficiency</div>
      </div>
    </div>
  );
}

export default Home;
