import React from "react";
import { Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "../styles/Resources.css"; // Import the CSS file

function ResourcesPage() {
  return (
    <div className="background">
      <div className="container">
        <div className="title">Industry Insights and Resources</div>
        {/*opening text */}
        <div className="res-content">
          At Trana Solutions, our philosophy is empowering our clients with
          knowledge and resources to navigate the financial landscape
          effectively. Below are some key statistics and resources that
          highlight the financial sector in Canada and the regulations that
          govern it.
        </div>
        {/* wrapper to help have text on left image on the right*/}
        <div className="content-wrapper">
          <div className="link-section">
            <div className="sectionTitle">
              Key Statistics in Canada’s Financial Sector:
            </div>
            <ul className="resourcesPage-links">
              <li>
                <Button
                  variant="text"
                  style={{ color: "#C5934E" }}
                  endIcon={<ArrowForwardIcon />}
                  href="https://www150.statcan.gc.ca/n1/en/type/analysis?keyword=labour%20market"
                  target="_blank"
                >
                  Staffing Industry
                </Button>
              </li>
              <li>
                <Button
                  variant="text"
                  style={{ color: "#C5934E" }}
                  endIcon={<ArrowForwardIcon />}
                  href="https://www150.statcan.gc.ca/n1/en/type/data?keyword=manufacturing"
                  target="_blank"
                >
                  Manufacturing Industry
                </Button>
              </li>
              <li>
                <Button
                  variant="text"
                  style={{ color: "#C5934E" }}
                  endIcon={<ArrowForwardIcon />}
                  href="https://www150.statcan.gc.ca/n1/en/type/data?keyword=construction"
                  target="_blank"
                >
                  Construction Industry
                </Button>
              </li>
            </ul>
            <div className="sectionTitle">Regulatory Resources:</div>
            <ul className="resourcesPage-links">
              <li>
                <Button
                  variant="text"
                  style={{ color: "#C5934E" }}
                  endIcon={<ArrowForwardIcon />}
                  href="https://laws-lois.justice.gc.ca/eng/acts/F-11.3/"
                  target="_blank"
                >
                  Canadian Financial Institutions Act
                </Button>
              </li>
              <li>
                <Button
                  variant="text"
                  style={{ color: "#C5934E" }}
                  endIcon={<ArrowForwardIcon />}
                  href="https://laws-lois.justice.gc.ca/eng/acts/P-24.501/"
                  target="_blank"
                >
                  Canada’s Anti-Money Laundering Regulations
                </Button>
              </li>
              <li>
                <Button
                  variant="text"
                  style={{ color: "#C5934E" }}
                  endIcon={<ArrowForwardIcon />}
                  href="https://www.ontario.ca/laws/statute/00e41"
                  target="_blank"
                >
                  Employment Standards Act
                </Button>
              </li>
              <li>
                <Button
                  variant="text"
                  style={{ color: "#C5934E" }}
                  endIcon={<ArrowForwardIcon />}
                  href="https://laws-lois.justice.gc.ca/eng/acts/O-2.1/"
                  target="_blank"
                >
                  Manufacturing Safety and Environmental Regulations
                </Button>
              </li>
            </ul>
          </div>
          <div className="image-section">
            <img
              src="/res.jpg"
              alt="Industry insights"
              className="resource-image"
            />
          </div>
        </div>
        <div className="content">
          Explore these resources to stay informed about the financial
          regulations and trends impacting your business.
        </div>
      </div>
    </div>
  );
}

export default ResourcesPage;
