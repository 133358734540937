import React from "react";
import "../styles/ServicePage.css";

function ServicePage() {
  return (
    <div className="background">
      <div className="container">
        <div className="title">What we do</div>
        {/* Section 1: Text on the left, Image on the right */}
        <div className="content-wrapper">
          <div className="service-left-content">
            <div className="service-title">
              1. Cost Management and Operational Optimization
            </div>
            <div className="content">
              Our primary goal is to help your business enhance your success
              through optimizing cost and operational management. We begin with
              a detailed analysis of your financial data, focusing on
              identifying opportunities to reduce expenses without compromising
              on quality or service.
            </div>
          </div>
          <div className="service-image">
            <img
              src="/service1.jpg"
              alt="Industry insights"
              className="cost-image"
            />
          </div>
        </div>
        <div className="service-content">
          <ul>
            <li>
              <strong>Expense Analysis:</strong> We conduct a thorough review of
              your operational expenses, identifying areas where costs can be
              reduced or managed more effectively.
            </li>
            <li>
              <strong>Process Optimization:</strong> By analyzing your existing
              operations, we recommend changes that streamline processes, reduce
              waste, and increase overall efficiency.
            </li>
            <li>
              <strong>Strategic Cost Reduction:</strong> Our team works closely
              with you to implement cost-saving strategies that align with your
              business goals, ensuring long-term financial health.
            </li>
          </ul>
        </div>
        {/* Section 2: Image on the left, Text on the right */}
        <div className="content-wrapper reverse">
          <div className="service-image">
            <img
              src="/streamline.jpg"
              alt="Industry insights"
              className="streamline-image"
            />
          </div>
          <div className="service-right-content">
            <br />
            <br />
            <br />
            <div className="service-title">2. Treasury Streamline</div>
            <div className="content">
              We understand the importance of accuracy and timeliness in
              managing payments. Our payment management services provide you
              with the flexibility and control you need to ensure that your
              payees are compensated correctly and on time.
            </div>
          </div>
        </div>
        <div className="service-content">
          <ul>
            <li>
              <strong>Comprehensive Payment Management:</strong> We handle all
              aspects of payment processing, including EFTs and Wire Transfers,
              ensuring that your financial obligations are met with precision.
            </li>
            <li>
              <strong>Enhanced Payment Controls:</strong> We introduce
              additional controls to your payment processes, helping you
              maintain compliance and ensure the completeness and accuracy of
              all transactions.
            </li>
            <li>
              <strong>Audit-Ready Systems:</strong> Our payment systems are
              designed with audits in mind, providing the transparency and
              traceability needed to satisfy regulatory requirements.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ServicePage;
