import React from "react";
import "../styles/Footer.css";

function Footer() {
  return (
    <footer className="footer">
      <div className="background">
        <div className="footer-container">
          <p>&copy; All rights reserved</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
